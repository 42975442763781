// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-administrador-establecimiento-js": () => import("./../../../src/pages/administradorEstablecimiento.js" /* webpackChunkName: "component---src-pages-administrador-establecimiento-js" */),
  "component---src-pages-administrador-estudiante-js": () => import("./../../../src/pages/administradorEstudiante.js" /* webpackChunkName: "component---src-pages-administrador-estudiante-js" */),
  "component---src-pages-administrador-familiar-js": () => import("./../../../src/pages/administradorFamiliar.js" /* webpackChunkName: "component---src-pages-administrador-familiar-js" */),
  "component---src-pages-administrador-libro-digital-js": () => import("./../../../src/pages/administradorLibroDigital.js" /* webpackChunkName: "component---src-pages-administrador-libro-digital-js" */),
  "component---src-pages-administrador-usuario-js": () => import("./../../../src/pages/administradorUsuario.js" /* webpackChunkName: "component---src-pages-administrador-usuario-js" */),
  "component---src-pages-credencial-usuario-js": () => import("./../../../src/pages/credencialUsuario.js" /* webpackChunkName: "component---src-pages-credencial-usuario-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */)
}

